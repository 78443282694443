<template>
  <div class="container">
    <div class="content">
      <div class="logo">
        <img src="@/assets/images/logo.jpg" alt="">
      </div>
      <div class="line"></div>
      <div class="title">登录</div>
      <div class="login-input">
        <img src="@/assets/images/user-icon.png" alt="">
        <input type="text" placeholder="用户名" v-model="formData.account" @keydown.enter.prevent="userLogin">
      </div>
      <div class="login-input">
        <img src="@/assets/images/password.png" alt="">
        <input type="password" placeholder="密码" v-model="formData.password" @keydown.enter.prevent="userLogin">
      </div>
<!--      <div class="remember">-->
<!--        <div @click="remember = !remember">-->
<!--          <img :src="getRememberImg()">-->
<!--          记住密码-->
<!--        </div>-->
<!--      </div>-->
      <div class="submit" @click="userLogin">登录</div>
    </div>
  </div>
</template>

<script>
import {userLogin} from "@/api/index.js";
import {Message,Loading} from "element-ui";
import myStorage from "@/utils/storage.js";
import myLocalStorage from "@/utils/loaclStorage.js";

export default {
  data(){
    return {
      formData:{
        account:'',
        password:'',
      },
      remember:false
    }
  },
  mounted() {
    this.getUserAccount();
  },
  methods:{
    getRememberImg(){
      return this.remember ? require("@/assets/images/checked.png") : require("@/assets/images/check.png");
    },
    //从LocalStorage里面拿记住的用户密码
    getUserAccount(){
      let userAccount = myLocalStorage.get('userAccount');
      if(userAccount){
        this.remember = true;
        this.formData.account = userAccount.account;
        this.formData.password = userAccount.password;
      }
    },
    //验证登录数据
    checkFormData(){
      if(!this.formData.account){
        Message.error('请输入账号');
        return false;
      }else if(!this.formData.password){
        Message.error('请输入密码');
        return false;
      }
      return true;
    },
    //登录
    async userLogin(){
      if(this.checkFormData()){
        const loading = Loading.service({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'})
        let res = await userLogin(this.formData);
        loading.close();
        if(res.data.code == 200){
          Message.success("登录成功");
          myStorage.set('token',res.data.data.token);
          myStorage.set('userInfo',res.data.data);
          if(this.remember){
            myLocalStorage.set('userAccount',{account: this.formData.account,password: this.formData.password});
          }else{
            myLocalStorage.remove('userAccount');
          }
          this.$router.push({
            path:'/'
          })
        }else{
          Message.error(res.data.msg);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background-color: #42b983;
  background-image: url("@/assets/images/logo-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .content{
    width: 400px;
    height: 440px;
    border-radius: 15px;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    padding: 10px 30px 30px;
    .logo{
      height: 70px;
      display: flex;
      justify-content: center;
      img{
        max-height: 100%;
      }
    }
    .line{
      width: 100%;
      height: 3px;
      background-color: #e1e1e1;
      margin: 10px 0;
    }
    .title{
      color: #333333;
      font-size: 18px;
      text-align: center;
      margin-bottom: 30px;
    }
    .login-input{
      width: 100%!important;
      margin-bottom: 15px;
      position: relative;
      img{
        width: 30px;
        height: 30px;
        position: absolute;
        top: 10px;
        left: 5px;
      }
      input{
        outline:none;
        height: 50px;
        line-height: 50px;
        border: 1px solid #e1e1e1;
        border-radius: 10px;
        width: 100%;
        font-size: 18px;
        text-indent: 40px;
        &:focus{
          border: 1px solid #919191;
        }
      }
    }
    .remember{
      font-size: 14px;
      margin-top: 10px;
      color: #999999;
      line-height: 16px;
      display: flex;
      &>div{
        &:hover{
          cursor: pointer;
        }
      }
      img{
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
    .submit{
      height: 50px;
      background-image: linear-gradient(to right,rgb(14,106,255),rgb(27,150,255));
      border-radius: 10px;
      margin: 30px 0;
      text-align: center;
      line-height: 50px;
      color: #ffffff;
      font-size: 24px;
      &:hover{
        cursor: pointer;
      }
    }
  }
}
</style>