import Axios from "axios";
import myStorage from "./storage";
import { HOST } from "@/config/config";
import {Loading, Message } from "element-ui";
import router from "@/router";
// create an axios instance
const axios = Axios.create({
  baseURL: HOST, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
});
// request interceptor
axios.interceptors.request.use(
  (config) => {
    config.headers["token"] = myStorage.get('token') || "";
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);
// response interceptor
axios.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    console.log(res)
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 400) {
        if(res.msg === 'NOTLOGIN'){
            Message.error('token已过期');
            myStorage.remove('token');
            myStorage.remove('userInfo');
            setTimeout(()=>{
                router.push({path:'/login'})
            },1000)
            return ;
        }
        return response;
    } else {
      return response;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
      Message.error('网络错误，请稍后再试');
      Loading.service().close();
    return Promise.reject(error);
  }
);
export default axios;
