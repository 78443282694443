<template>
  <div>
    <div class="header">
      <div class="btn" @click="loginOut">退出登录</div>
    </div>
    <div class="banner"></div>
    <div class="container">
      <ul class="cx-fex-l">
        <li v-for="(item) in videoList" :key="item.id" @click="toDetails(item.id)">
          <div class="cx-fex">
            <div class="left">
              <div class="image" :style="{backgroundImage:`url(${item.resource})`}"></div>
            </div>
            <div class="right cx-fex cx-fex-column">
              <div>{{ item.name }}</div>
              <div class="time">发布时间：{{ item.create_time }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import {getVideoList} from "@/api/index.js";
import myStorage from "@/utils/storage.js"
export default {
  data(){
    return {
      videoList:[],
    }
  },
  created() {
    this.getVideoList();
  },
  methods:{
    async getVideoList(){
      let res = await getVideoList();
      if(res.data.code == 200){
        this.videoList = res.data.data;
      }
    },
    toDetails(id){
      this.$router.push({
        path:'/details',
        query:{id}
      })
    },
    loginOut(){
      myStorage.clear();
      this.$router.push({path:'/login'})
    }
  }
}
</script>

<style lang="scss" scoped>
  .header{
    width: 100%;
    height: 50px;
    background: #ffffff;

    .btn{
      margin-top: 10px;
      float: right;
      margin-right: 100px;
      background: #3383FF;
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      line-height: 30px;
      padding: 0 30px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .banner{
    width: 100%;
    min-width: 1200px;
    height: 200px;
    background-image: url("@/assets/images/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .container{
    width: 1200px;
    margin: 0 auto;
    ul{
      li{
        display: flex;
        width: 50%;
        padding: 20px;
        &>div{
          background-color: #ffffff;
          border: 1px solid #f1f1f1;
          box-shadow: 0 0 10px rgba(0,0,0,0.1);
          padding: 20px;
          width: 100%;
          .left{
            width: 40%;
            background-color: #f7f7f7;
            .image{
              padding-bottom: 75%;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
          .right{
            width: 60%;
            padding-left: 20px;
            font-size: 18px;
            .time{
              font-size: 14px;
              color: #999999;
            }
          }
        }
      }
    }
  }
</style>