<template>
  <div>
    <div class="header">
      <div class="btn" @click="$router.back()">返回列表</div>
    </div>
    <div v-if="details">
      <div class="video-cont">
        <video
            :src="details.video"
            controls
        ></video>
      </div>
      <div class="video-detail cx-fex-l cx-fex-column">
        <div class="title">{{ details.name }}</div>
        <div> {{ details.description}}</div>
        <div>上传时间： {{ details.create_time}}</div>
      </div>
    </div>
  </div>

</template>

<script>
import {getVideoDetails} from "@/api/index.js";
export default {
  data(){
    return{
      details:null,
    }
  },
  created() {
    this.getVideoDetails();
  },
  methods:{
    async getVideoDetails(){
      let res = await getVideoDetails({id:this.$route.query.id});
      if(res.data.code == 200){
        this.details = res.data.data;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  width: 100%;
  height: 50px;
  background: #ffffff;

  .btn{
    margin-top: 10px;
    float: left;
    margin-left: 50px;
    background: #3383FF;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    line-height: 30px;
    padding: 0 30px;
    &:hover{
      cursor: pointer;
    }
  }
}
  .video-cont{
    width: 100vw;
    height: calc(100vh - 169px);
    background: #000000;
    video{
      width: 100%;
      height: 100%;
    }
  }
  .video-detail{
    width: 100%;
    background-color: #2B2F31;
    padding: 30px;
    color: #ffffff;
    font-size: 14px;
    &>div{
      margin-top: 15px;
    }
    .title{
      font-size: 20px;
    }
  }
</style>