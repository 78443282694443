import request from "@/utils/request";
//用户登录
export const userLogin = (data)=>{
    return request.post('/api/userLogin',data);
}
//获取视频列表
export const getVideoList = (data) => {
    return request.post('/api/videoList', data);
}
//获取视频详情
export const getVideoDetails = (params) => {
    return request.get('/api/videoFind', {params});
}